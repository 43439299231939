import { PlatformSettingsResponse } from 'hq/services/admin-settings-service.types';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { Grid } from '@mui/material';
import { NoShowBailInput } from './no-show-bail-input';
import { mapPlatformSettingsToForm } from '../helpers/map-settings-to-form';

type Props = {
  data: PlatformSettingsResponse;
  onSubmit: (data: Partial<PlatformSettingsFormData>) => void;
};

export type PlatformSettingsFormData = {
  noShowBail: number;
};

export const PlatformSettingsForm = ({ data, onSubmit }: Props) => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();

  const methods = useForm<PlatformSettingsFormData>({
    defaultValues: mapPlatformSettingsToForm(data),
  });
  const changedSettings = methods.formState.dirtyFields;

  const handleSubmit = useCallback(
    (formData: PlatformSettingsFormData) => {
      const fieldsToUpdate: Partial<PlatformSettingsFormData> = {};
      for (const key in changedSettings) {
        const value = formData[key as keyof PlatformSettingsFormData];
        fieldsToUpdate[key as keyof PlatformSettingsFormData] = value;
      }

      onSubmit(fieldsToUpdate);
    },
    [changedSettings]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Page
          header={<Header titleText="Platform instellingen wijzigen" />}
          toolbox={
            <Toolbox>
              <LwButton color="primary" type="submit">
                Aanpassen
              </LwButton>
              <LwButton
                iconColorMode="fill"
                color="secondary"
                onClick={() => navigate(generatePath(hqRoutes.PlatformSettings))}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <NoShowBailInput />
            </Grid>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
};

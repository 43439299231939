import { Grid, ListSubheader, MenuItem } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { useOgpGetShiftCreateData } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift-create-data';
import { JobListItem, TakeDefined } from '@types';

type JobInputsFields = {
  departmentId: string;
  jobId: string;
};

export const JobInputs = ({
  disabled,
  jobsData,
  branchOfficesWithDepartments,
  isJobSelectorVisible,
}: {
  disabled: boolean;
  jobsData: JobListItem[];
  branchOfficesWithDepartments: TakeDefined<
    ReturnType<typeof useOgpGetShiftCreateData>['data']
  >['branchOfficesWithDepartments'];
  isJobSelectorVisible?: boolean;
}) => {
  const { watch } = useFormContext<JobInputsFields>();
  const watchedDepartment = watch('departmentId');

  const jobOptions = useMemo(
    () =>
      jobsData
        .filter((i) => i.departmentId === watchedDepartment)
        .map((job) => ({
          value: job.id,
          label: (
            <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>
              {job.name}
            </FlexPoolOnlyIndicator>
          ),
        })),
    [jobsData, watchedDepartment]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <DepartmentFormElement options={branchOfficesWithDepartments} disabled={disabled} />
      </Grid>
      {isJobSelectorVisible ? (
        <Grid item xs={6}>
          <JobFormElement options={jobOptions} disabled={disabled} />
        </Grid>
      ) : null}
    </Grid>
  );
};

const DepartmentFormElement = ({
  options,
  disabled,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { setValue, control } = useFormContext<JobInputsFields>();
  return (
    <LwFormSelect
      renderOption={(opt) => [
        <ListSubheader key={opt.value} value="">
          <em>{opt.label}</em>
        </ListSubheader>,
        opt.options.map((dpt) => (
          <MenuItem key={dpt.value} value={dpt.value}>
            {dpt.label}
          </MenuItem>
        )),
      ]}
      name="departmentId"
      label="Afdeling"
      defaultLabel="Selecteer een afdeling"
      options={options}
      disabled={disabled}
      control={control}
      rules={{ required: 'Selecteer een afdeling' }}
      data-testid="branch-office-select"
      onChange={() => {
        setValue('jobId', '');
      }}
    />
  );
};

const JobFormElement = ({
  options,
  disabled,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { watch, control } = useFormContext<JobInputsFields>();
  const selectedDepartment = watch('departmentId');
  return (
    <LwFormSelect
      name="jobId"
      label="Functie"
      defaultLabel="Selecteer een functie..."
      options={options}
      disabled={disabled || !selectedDepartment}
      control={control}
      data-testid="job-select"
      rules={{ required: 'Selecteer een functie' }}
    />
  );
};

import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { StateError, StateLoading } from 'shared/components/common/state/State';
import { LwFormInput, LwFormMultiSelect, LwFormSelect, LwFormTextArea } from 'redesign';
import { Box, Grid, ListSubheader, MenuItem, styled, Typography } from '@mui/material';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments';
import { OGPUpdateFlexPoolPayload } from '../../../../../../services/flex-pool-service.types';

interface Props {
  state: 'create' | 'update' | 'read';
}

const FlexPoolForm = ({ state }: Props) => {
  const { watch } = useFormContext<OGPUpdateFlexPoolPayload>();
  const departmentId = watch('departmentId');
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });
  const jobsQuery = useGetJobs(departmentId || undefined);
  const queries = [departmentsQuery, jobsQuery];

  if (queries.some((query) => query.isLoading)) {
    return <StateLoading />;
  }

  if (queries.some((query) => query.isError)) {
    return <StateError error={queries.find((query) => query.isError)?.error ?? null} />;
  }

  return (
    <Grid container item spacing={6} xs={12}>
      <Grid item xs={6}>
        <FlexPoolFormImpl
          state={state}
          departments={departmentsQuery.data}
          jobs={jobsQuery.data}
          departmentId={departmentId}
        />
      </Grid>
      <Grid item xs={6}>
        <Disclaimer>
          <Typography>
            Let op; wijzigingen gemaakt aan een flexpool heeft alleen effect voor nieuw
            gepubliceerde shifts. Bestaande shifts worden niet aangepast.
          </Typography>
        </Disclaimer>
      </Grid>
    </Grid>
  );
};

const FlexPoolFormImpl = ({
  state,
  departments,
  jobs,
  departmentId,
}: Props & {
  departments: ReturnType<typeof useGetDepartmentsAsSelect>['data'];
  jobs: ReturnType<typeof useGetJobs>['data'];
  departmentId: string;
}) => {
  const { control, setValue } = useFormContext<OGPUpdateFlexPoolPayload>();

  useEffect(() => {
    if (state === 'create') {
      setValue('jobs', []);
      return;
    }
  }, [departmentId, setValue, state]);

  const jobOptions = useMemo(() => {
    if (!jobs) {
      return [];
    }
    return jobs
      .filter((job) => departmentId === job.departmentId)
      .map(({ id, name }) => ({ id, name }));
  }, [departmentId, jobs]);

  return (
    <form>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={6}>
          <LwFormSelect
            name="departmentId"
            label="Afdeling"
            defaultLabel="Selecteer een afdeling..."
            options={departments ?? []}
            renderOption={(opt) => [
              <ListSubheader key={opt.value} value={opt.value}>
                <em>{opt.label}</em>
              </ListSubheader>,
              opt.options.map((dpt) => (
                <MenuItem key={dpt.value} value={dpt.value}>
                  {dpt.label}
                </MenuItem>
              )),
            ]}
            disabled={state === 'read' || state === 'update'}
            control={control}
            rules={{ required: 'Selecteer een afdeling' }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="name"
            label="Flexpool naam"
            control={control}
            rules={{ required: 'Voer een flexpoolnaam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormTextArea name="description" control={control} label="Omschrijving" />
        </Grid>

        <Grid item xs={12}>
          <LwFormMultiSelect
            name="jobs"
            control={control}
            rules={{ required: 'Selecteer minstens één functie' }}
            options={jobOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => {
              return option.name;
            }}
            disabled={!departmentId}
            label="Functies"
          />
        </Grid>
      </Grid>
    </form>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(4),
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
}));

export { FlexPoolForm };

import { Button, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useApproveFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useApproveFlexWorkerIdentityCheck';
import { useGetFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkerIdentityCheck';
import { useRejectFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useRejectFlexWorkerIdentityCheck';
import { useState } from 'react';
import { RenderUnsettledUI } from 'shared/components';
import { useAlert } from 'shared/components/alerts';
import { WorkerEmploymentType } from '@types';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { Controller, useForm } from 'react-hook-form';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FlexWorkerIdentityVerificationForm } from './flex-worker-id-check.types';

const FlexWorkerIdCheck = ({
  flexWorkerId,
  closeModal,
  employmentType,
}: {
  flexWorkerId: string;
  closeModal: () => void;
  employmentType: WorkerEmploymentType | null;
}) => {
  const { alertError } = useAlert();
  const styles = getStyles();
  const { data, status, error, refetch } = useGetFlexWorkerIdentityCheck(flexWorkerId);
  const [isIdDocumentDownloaded, setIsIdDocumentDownloaded] = useState(false);
  const { allowRequiredVatForWorkActions } = useFeatureFlagContext();
  const invalidateQuery = useInvalidateQuery();
  const identityVerificationId = data?.id;
  const isBSNFieldShown =
    employmentType === 'FREELANCER' &&
    allowRequiredVatForWorkActions === false &&
    data?.vatNumber === undefined &&
    !data?.bsn;

  const { handleSubmit, control } = useForm<FlexWorkerIdentityVerificationForm>({
    defaultValues: {
      bsn: data?.bsn ?? undefined,
    },
  });

  const approveMutation = useApproveFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });
  const denyMutation = useRejectFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });

  const onApprove = (formData: FlexWorkerIdentityVerificationForm) => {
    approveMutation.mutate(formData.bsn);
    closeModal();
  };

  const onDeny = async () => {
    await denyMutation.mutateAsync();
    closeModal();
  };

  const viewIdDocument = () => {
    setIsIdDocumentDownloaded(true);
    window.open(data?.idDocumentImage);
  };

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  return (
    <form onSubmit={handleSubmit(onApprove)}>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">ID Check</h2>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <img src={data.portraitImage} alt="Portrait" className={styles.image} />
        </Grid>
        <Grid item xs={6} spacing={2}>
          <img src={data.selfieImage} alt="Selfie" className={styles.image} />
        </Grid>
        {data.idDocumentImage ? (
          <Grid item xs={12}>
            <Button
              onClick={viewIdDocument}
              className={styles.button}
              variant="contained"
              size="large"
              color="primary"
            >
              <Typography variant="body2">Bekijk identiteitsbewijs</Typography>
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <TextField
            title="Voornaam"
            value={data.firstName}
            label="Voornaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            title="Achternaam"
            value={data.lastName}
            label="Achternaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            title="Geboortedatum"
            value={new Date(data.dateOfBirth).toLocaleDateString('nl-NL')}
            label="Geboortedatum"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        {isBSNFieldShown ? (
          <Grid item xs={4}>
            <Controller
              control={control}
              name="bsn"
              render={({ field: { onChange } }) => (
                <TextField
                  title="BSN"
                  value={data.bsn}
                  label="BSN"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <Button
            className={styles.button}
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={
              data.idDocumentImage
                ? employmentType === 'TEMPWORKER' && !isIdDocumentDownloaded
                : false
            }
          >
            <Typography variant="body2">Goedkeuren</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={styles.button}
            onClick={onDeny}
            variant="contained"
            size="large"
            color="primary"
          >
            <Typography variant="body2">Afwijzen</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const getStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '300px',
  },
  image: {
    width: '100%',
  },
  buttonContainer: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  infoContainer: {},
  button: { marginRight: 12 },
}));

export { FlexWorkerIdCheck };

import { EnvironmentService } from 'shared/services';
import { HqApiService } from './hqApiService';
import { BranchOfficeService } from './branch-office-service';
import { FlexWorkerService } from './flex-worker-service';
import { FlexPoolService } from './flex-pool-service';
import { ShiftClaimService } from './shift-claim-service';
import { UserService } from './userService';
import { JobService } from './job-service';
import { AdminSettingsService } from './admin-settings-service';
import { CompanyService } from './company-service';
import { BillingEntityService } from './billing-entity-service';
import { CollectiveInvoicesService } from './collective-invoices-service';
import { ShiftPlanningService } from './shift-planning-service';
import { AccreditationsService } from './accreditations-service';
import { ShiftService } from './shift-service';
import { ShiftApplicationService } from './shift-application-service';
import { PlacementService } from './placement-service';
import { PartnerService } from './partner-service';
import { PartnerUserService } from './partner-user-service';
import { ProjectPlanningService } from './project-planning-service';
import { ProjectService } from './project-service';
import { WorkApplicationService } from './work-application-service';
import { DepartmentService } from './department-service';
import { JobTypeService } from './job-type-service';

const endpoint = EnvironmentService.getVariable('BACKEND_HQ_URI');

const hqApiService = new HqApiService({ endpoint });
export const companyService = new CompanyService(hqApiService);
export const branchOfficeService = new BranchOfficeService(hqApiService);
export const flexWorkerService = new FlexWorkerService(hqApiService);
export const flexPoolService = new FlexPoolService(hqApiService);
export const shiftClaimService = new ShiftClaimService(hqApiService);
export const userService = new UserService(hqApiService);
export const jobService = new JobService(hqApiService);
export const jobTypeService = new JobTypeService(hqApiService);
export const adminSettingsService = new AdminSettingsService(hqApiService);
export const billingEntityService = new BillingEntityService(hqApiService);
export const collectiveInvoicesService = new CollectiveInvoicesService(hqApiService);
export const shiftPlanningService = new ShiftPlanningService(hqApiService);
export const projectPlanningService = new ProjectPlanningService(hqApiService);
export const accreditationsService = new AccreditationsService(hqApiService);
export const shiftService = new ShiftService(hqApiService);
export const placementService = new PlacementService(hqApiService);
export const shiftApplicationService = new ShiftApplicationService(hqApiService);
export const partnerService = new PartnerService(hqApiService);
export const partnerUserService = new PartnerUserService(hqApiService);
export const projectService = new ProjectService(hqApiService);
export const workApplicationService = new WorkApplicationService(hqApiService);
export const departmentService = new DepartmentService(hqApiService);

import { HQCreateShiftPayload, HQShiftPayloadBase } from 'hq/services/shift-service.types';
import { OgpShiftPayloadBase } from 'ogp/services/shift-service.types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

export const transformData = (
  formData: FreelanceShiftFormData | TempWorkShiftFormData
): HQCreateShiftPayload | OgpShiftPayloadBase => {
  const baseShiftPayload: HQShiftPayloadBase | OgpShiftPayloadBase = {
    startDate: `${formData.startDate}T${formData.startTime}`,
    endDate: `${formData.endDate}T${formData.endTime}`,
    maxClaimants: +formData.maxClaimants,
    breakMinutes: formData.breakMinutes,
  };

  if (isFreelanceShiftFormData(formData)) {
    baseShiftPayload.freelanceProperties = {
      hourlyRateCents: Math.round(formData.hourlyRate * 100),
      mutableUntilMinutes: formData.mutableUntilHours * 60,
    };
  }

  if (isTempworkCreateData(formData)) {
    baseShiftPayload.tempWorkProperties = {
      grossHourlyRateCents: Math.round(formData.grossHourlyRate * 100),
    };
  }

  return baseShiftPayload;
};

const isFreelanceShiftFormData = (
  formData: FreelanceShiftFormData | TempWorkShiftFormData
): formData is FreelanceShiftFormData => {
  return formData.isFreelance;
};

const isTempworkCreateData = (
  formData: FreelanceShiftFormData | TempWorkShiftFormData
): formData is TempWorkShiftFormData => {
  return formData.isTempwork;
};

import { Level, WorkerEmploymentType } from '@types';

export const getAvailableLevelUpgrades = (
  level: Level,
  employmentType: WorkerEmploymentType,
  allowCandidateOnboarding: boolean
) => {
  switch (level) {
    case 'EXPLORER': {
      if (employmentType === 'FREELANCER') {
        return [
          { value: 'EXPLORER', label: getLevelLabel('EXPLORER') },
          { value: 'STARTER', label: getLevelLabel('STARTER') },
        ];
      } else {
        return allowCandidateOnboarding
          ? [
              { value: 'EXPLORER', label: getLevelLabel('EXPLORER') },
              { value: 'CANDIDATE', label: getLevelLabel('CANDIDATE') },
            ]
          : [
              { value: 'EXPLORER', label: getLevelLabel('EXPLORER') },
              { value: 'WORKER', label: getLevelLabel('WORKER') },
            ];
      }
    }
    case 'STARTER':
      return [
        { value: 'STARTER', label: getLevelLabel('STARTER') },
        { value: 'WORKER', label: getLevelLabel('WORKER') },
      ];
    case 'CANDIDATE':
      return [
        { value: 'CANDIDATE', label: getLevelLabel('CANDIDATE') },
        { value: 'WORKER', label: getLevelLabel('WORKER') },
      ];
    case 'WORKER':
      return [{ value: 'WORKER', label: getLevelLabel('WORKER') }];
    default:
      return [];
  }
};

export const getLevelLabel = (level: Level) => {
  switch (level) {
    case 'EXPLORER':
      return 'Explorer';
    case 'STARTER':
      return 'Starter';
    case 'CANDIDATE':
      return 'Candidate';
    case 'WORKER':
      return 'Worker';
    default:
      return 'Incomplete';
  }
};

export const getNextLevelRequirementsText = (
  level: Level,
  employmentType: WorkerEmploymentType,
  allowCandidateOnboarding: boolean
) => {
  if (level === 'CANDIDATE') {
    return 'Om een uitzendkracht naar een worker te upgraden, moet de werker een geboortedatum, BSN, IBAN en CV (indien mogelijk) hebben.';
  }
  if (level === 'STARTER') {
    return 'Om een freelancer naar een worker te upgraden, moet de werker 3 shifts gewerkt hebben en een btw-id hebben.';
  }
  if (level === 'EXPLORER') {
    if (employmentType === 'FREELANCER') {
      return 'Om een freelancer naar een starter te upgraden, moet de werker een geboortedatum, BSN, IBAN en CV (indien mogelijk) hebben.';
    } else {
      return allowCandidateOnboarding
        ? 'Om een uitzendkracht naar een kandidaat te upgraden, moet de werker een geboortedatum, BSN, IBAN en CV (indien mogelijk) hebben.'
        : 'Om een uitzendkracht naar een worker te upgraden, moet de werker een geboortedatum, BSN, IBAN en CV (indien mogelijk) hebben.';
    }
  }
  if (level === 'WORKER') {
    return 'Flexwerker is al een worker';
  }
  return 'De flexwerkersstaat is onvolledig';
};

import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback } from 'react';
import { UncontrolledFormSwitch } from 'redesign';
import { useGetNoShowReasons } from '../../../../../ogp/hooks/queryHooks/noShowReasons/useGetNoShowReasons';
import { NoShowReason } from '../../../../../ogp/services/noShowReasonService';
import { useToggle } from '../../../../hooks';
import { invariant } from '../../../../utils/utils';
import { SmallError } from '../../../common/state/SmallError';
import { SmallLoader } from '../../../common/state/SmallLoader';
import { RenderUnsettledUI } from '../../../render-unsettled-ui';
import { NoShowPayloadHandlerFn } from '../use-no-show-dialog.types';

type Props = {
  onChange: NoShowPayloadHandlerFn;
  onSubmit: () => void;
  isFlexWorker: boolean;
};

export const NoShowFormDataProvider = ({ onChange, onSubmit, isFlexWorker }: Props) => {
  const { data, error, status, refetch } = useGetNoShowReasons();

  if (status !== 'success') {
    return (
      <RenderUnsettledUI
        data={{ error, status, refetch }}
        renderError={(error, refetch) => <SmallError error={error} retry={refetch} />}
        renderLoading={<SmallLoader />}
      />
    );
  }

  return (
    <NoShowForm onChange={onChange} onSubmit={onSubmit} data={data} isFlexWorker={isFlexWorker} />
  );
};

const NoShowForm = ({
  data,
  onChange,
  onSubmit,
  isFlexWorker,
}: Props & { data: NoShowReason[] }) => {
  const [noShowReason, setNoShowReason] = React.useState<NoShowReason>();
  const {
    on: consequencesEnabled,
    setOff: disableConsequences,
    toggle: toggleConsequences,
  } = useToggle(false);

  const handleNoShowReasonChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const reason = data.find((reason) => reason.id === e.target.value);
      setNoShowReason(reason);

      if (!reason?.allowsConsequences) {
        disableConsequences();
      }
    },
    [data, disableConsequences]
  );

  const handleConsequencesChange = useCallback(() => {
    invariant(noShowReason);
    toggleConsequences();
  }, [noShowReason, toggleConsequences]);

  React.useEffect(() => {
    if (!noShowReason) {
      return;
    }

    onChange({
      noShowWithConsequences: consequencesEnabled,
      noShowReason,
    });
  }, [consequencesEnabled, noShowReason, onChange]);

  return (
    <form
      id="no-show-reason-form"
      aria-label="no-show-reason-form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Stack spacing={6}>
        <Typography variant="body1">
          Wat is de reden van de no show van de flexwerker en heeft diegene zich afgemeld?
        </Typography>
        <RadioGroup
          name="no-show-reason"
          value={noShowReason?.id ?? ''}
          onChange={handleNoShowReasonChange}
        >
          {data.map((reason) => (
            <FormControlLabel
              key={reason.id}
              value={reason.id}
              control={<Radio required />}
              label={
                noShowReason?.id === reason.id ? (
                  <strong>{reason.longDescription}</strong>
                ) : (
                  reason.longDescription
                )
              }
            />
          ))}
        </RadioGroup>
      </Stack>
      {isFlexWorker && noShowReason?.allowsConsequences ? (
        <>
          <Box my={4}>
            <Divider light />
          </Box>
          <Stack spacing={2}>
            <Typography variant="body1">
              Ben je van mening dat flexwerker een consequentie verdient?
            </Typography>
            <Typography variant="body2">
              <strong style={{ fontSize: 'inherit' }}>Let op:</strong> dit is niet mogelijk in het
              geval van een ziekmelding.
            </Typography>
          </Stack>

          <Box display="flex">
            <Toggle
              value={consequencesEnabled}
              checked={consequencesEnabled}
              onChange={() => handleConsequencesChange()}
              name="allowConsequence"
              label=""
            />
            <Stack spacing={4} disabled={!consequencesEnabled}>
              <TypographyWithMarginTop variant="subtitle1">
                <label htmlFor="allowConsequences">
                  <strong>
                    {consequencesEnabled
                      ? 'Ja, consequentie is terecht'
                      : 'Nee, consequentie is niet terecht'}
                  </strong>
                </label>
              </TypographyWithMarginTop>
              {consequencesEnabled ? (
                <>
                  <Typography variant="body1">
                    De flexwerker wordt gedeactiveerd op Level.works en betaalt een borg, die
                    hij/zij na een aantal shifts weer terug kan verdienen.
                  </Typography>
                  <Typography variant="body1">
                    <strong>Let op:</strong> Je bent zelf verantwoordelijk om deze persoon uit je
                    flexpools te verwijderen indien gewenst.
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  De flexwerker blijft in je flexpool. Wij ondernemen geen actie. Een foutje of een
                  keer ziek zijn kan tenslotte gebeuren!
                </Typography>
              )}
            </Stack>
          </Box>
        </>
      ) : null}
      {!isFlexWorker ? (
        <Typography variant="subtitle1">
          <strong>Er zijn geen consequenties voor partner flexwerkers.</strong>
        </Typography>
      ) : null}
    </form>
  );
};

const TypographyWithMarginTop = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));

const Stack = styled(Box)<{ disabled?: boolean; spacing: 2 | 4 | 6 }>((props) => ({
  '& *': {
    color: props.disabled ? grey['500'] : 'inherit',
  },
  '& > * + *': {
    marginBottom: props.theme.spacing(props.spacing),
    marginTop: props.theme.spacing(props.spacing),
  },
}));

const Toggle = styled(UncontrolledFormSwitch)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(4),
  marginLeft: theme.spacing(2),
  alignItems: 'flex-start',
}));

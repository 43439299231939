import { Box, Grid, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { SkillType } from '@types';
import { useGetJobDetails } from './hooks/use-get-job-details';

export const WorkTemplateJobDetails = ({ jobId }: { jobId: string }) => {
  const jobDetailsQuery = useGetJobDetails(jobId);

  if (jobDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobDetailsQuery} />;
  }

  const jobDetails = jobDetailsQuery.data;
  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="secondary">
            Contactpersoon
          </Typography>
          <Typography variant="body1">{jobDetails.contactName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="secondary">
            Telefoon contactpersoon
          </Typography>
          <Typography variant="body1">{jobDetails.contactPhoneNumber}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Locatie
          </Typography>
          <Typography variant="body1">{jobDetails.location.address}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Korte omschrijving
          </Typography>
          <Typography variant="body1">{jobDetails.descriptionShort}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Lange omschrijving
          </Typography>
          <Typography variant="body1">{jobDetails.descriptionLong}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Afspraken en vereisten
          </Typography>
          <Typography variant="body1">{jobDetails.descriptionLawRequirements}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Functiegroep certificaten
            </Typography>
          </Box>

          <Box>
            {jobDetails.jobTypeCertificates.length > 0 ? (
              jobDetails.jobTypeCertificates.map(
                (cert: { certificate: { id: string; name: string } }) => {
                  return <Box key={cert.certificate.id}>{cert.certificate.name}</Box>;
                }
              )
            ) : (
              <Typography variant="body1" color="secondary">
                Geen certificaten
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Aanvullende certificaten
            </Typography>
          </Box>

          <Box>
            {jobDetails.jobCertificates.length > 0 ? (
              jobDetails.jobCertificates.map(
                (cert: { certificate: { id: string; name: string } }) => {
                  return <Box key={cert.certificate.id}>{cert.certificate.name}</Box>;
                }
              )
            ) : (
              <Typography variant="body1" color="secondary">
                Geen certificaten
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Werkvereisten
            </Typography>
          </Box>

          <Box>
            {jobDetails.jobRequirements.length > 0 ? (
              jobDetails.jobRequirements.map(
                (req: { id: string; name: string; type: SkillType }) => {
                  return <Box key={req.id}>{req.name}</Box>;
                }
              )
            ) : (
              <Typography variant="body1" color="secondary">
                Geen werkvereisten
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

import { Box, styled } from '@mui/material';
import { ModalPanelBody, ModalPanelTabType } from 'redesign';
import { FlexWorkerProfileData, SkillType } from '@types';
import { ProfileCertificates } from './profile-certificates';
import { ProfileHeader } from './profile-header';
import { ProfileQualifications } from './profile-qualifications';
import { useSiteDetection } from '../../../../../../shared/hooks';
import { useFeatureFlagContext } from '../../../../../../shared/contexts/feature-flag-provider';
import { ProfileWorkExperienceTab } from '../../../../../../shared/components/common/work-experience/work-experience-tab';
import { FlexWorkerAvailability } from '../../../../../../shared/components/flex-worker-availability/flex-worker-availability';

const Profile = ({ data, id }: { data: FlexWorkerProfileData; id: string }) => {
  const { isHQ } = useSiteDetection();
  const { allowAvailability } = useFeatureFlagContext();

  const options = getPanelBodyOptions(isHQ, allowAvailability, data, id);

  return (
    <WrapperBoxProfile>
      <ProfileHeader data={data} />
      <ModalPanelBody tabs={options} />
    </WrapperBoxProfile>
  );
};

const WrapperBoxProfile = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(8)} 0 ${theme.spacing(8)} ${theme.spacing(8)}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const getPanelBodyOptions = (
  isHQ: boolean,
  allowAvailability: boolean,
  data: FlexWorkerProfileData,
  id: string
): ModalPanelTabType[] => {
  const options: ModalPanelTabType[] = [
    {
      id: 'Certificates',
      tabLabel: 'Certificaten',
      element: <ProfileCertificates flexWorkerId={id} />,
    },
    {
      id: 'Skills',
      tabLabel: 'Vaardigheden',
      element: (
        <ProfileQualifications
          data={data.qualifications.filter((q) => q.category !== SkillType.APPAREL)}
        />
      ),
    },
    {
      id: 'Apparel',
      tabLabel: 'Kleding en uiterlijk',
      element: (
        <ProfileQualifications
          data={data.qualifications.filter((q) => q.category === SkillType.APPAREL)}
        />
      ),
    },
  ];

  if (!data.partnerName) {
    options.push({
      id: 'Werkervaring',
      tabLabel: 'Werkervaring',
      element: <ProfileWorkExperienceTab workExperiences={data.workExperiences} />,
    });

    if (isHQ && allowAvailability) {
      options.push({
        id: 'Availability',
        tabLabel: 'Beschikbaarheid',
        element: <FlexWorkerAvailability flexWorkerId={id} />,
      });
    }
  }

  return options;
};

export { Profile };

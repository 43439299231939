import { ProfileQualification } from '@types';
import { Box, Divider, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
  SkillBox,
  StyledWorkRequirementsChip,
} from '../../../../../../shared/components/popup-profile/styled-mui-components';

const ProfileQualifications = ({ data }: { data?: ProfileQualification[] }) => {
  const sortedSkills = useMemo(
    () =>
      data
        ? data.map((item) => ({
            label: item.label,
            skills: [...item.skills].sort(),
          }))
        : [],
    [data]
  );

  return (
    <ContainerBox>
      {sortedSkills?.map(({ label, skills }, index) => (
        <Box key={label} data-testid="profile-qualifications-container">
          <ProfileQualificationLabel data-testid="skill-category-label" variant="body1">
            {label}
          </ProfileQualificationLabel>
          <SkillBox>
            {skills && skills.length > 0 ? (
              skills.map((s) => (
                <StyledWorkRequirementsChip label={s} key={s} data-testid="skill-chip" />
              ))
            ) : (
              <Typography variant="subtitle1">
                De flexwerker heeft nog geen vaardigheden toegevoegd
              </Typography>
            )}
          </SkillBox>
          {index !== skills.length - 1 && (
            <Box mt={4} mb={4} style={{ width: '100%' }}>
              <StyledDivider />
            </Box>
          )}
        </Box>
      ))}
    </ContainerBox>
  );
};

const ContainerBox = styled(Box)(() => ({
  padding: '10px 4px 0 4px',
  marginRight: '32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const ProfileQualificationLabel = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '16px',
}));

const StyledDivider = styled(Divider)(() => ({
  borderColor: 'rgba(0, 0, 0, 0.2)',
  backgroundColor: 'transparent',
}));

export { ProfileQualifications };

import {
  AllJobFormData,
  BaseJobData,
  FreelanceJobDetailsWithId,
  FreelanceJobFormData,
  isFreelanceJob,
  isTempWorkJob,
  MultipleJobFormData,
  TempWorkJobDetailsWithId,
  TempWorkJobFormData,
} from '@types';

export function encodeJobToJobForm(job: FreelanceJobDetailsWithId): FreelanceJobFormData;
export function encodeJobToJobForm(job: TempWorkJobDetailsWithId): TempWorkJobFormData;
export function encodeJobToJobForm(
  job: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId
): FreelanceJobFormData | TempWorkJobFormData;
export function encodeJobToJobForm(
  job: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId
): FreelanceJobFormData | TempWorkJobFormData | MultipleJobFormData {
  const base = {
    name: job.name,
    descriptionShort: job.descriptionShort,
    descriptionLawRequirements: job.descriptionLawRequirements,
    descriptionLong: job.descriptionLong,
    contactName: job.contactName,
    contactPhoneNumber: job.contactPhoneNumber,
    location: job.location,
    locationUrl: job.locationUrl,
    departmentId: job.departmentId,
    breakMinutes: job.breakMinutes,
    imageUrl: job.imageUrl,
    VAT: job.VAT,
    flexPoolOnly: job.flexPoolOnly,
    costCenter: job.costCenter,
    jobTypeId: job.jobTypeId,
    ortApplicable: job.ortApplicable,
    jobRequirements: job.jobRequirements,
    requiredCertificates: job.requiredCertificates,
    jobCertificates: job.jobCertificates,
  };

  if (isFreelanceJob(job) && isTempWorkJob(job)) {
    return <MultipleJobFormData>{
      hourlyRate: job.freelanceJob.hourlyRateCents / 100,
      grossHourlyRate: job.tempWorkJob.grossHourlyRateCents / 100,
      cla: job.tempWorkJob.cla,
      mutableUntilHours: job.freelanceJob.mutableUntilMinutes / 60,
      isFreelance: true,
      isTempwork: true,
      ...base,
    };
  }
  if (isFreelanceJob(job)) {
    return {
      hourlyRate: job.freelanceJob.hourlyRateCents / 100,
      mutableUntilHours: job.freelanceJob.mutableUntilMinutes / 60,
      isFreelance: true,
      isTempwork: false,
      ...base,
    };
  }
  if (isTempWorkJob(job)) {
    return {
      grossHourlyRate: job.tempWorkJob.grossHourlyRateCents / 100,
      cla: job.tempWorkJob.cla,
      isFreelance: false,
      isTempwork: true,
      ...base,
    };
  }
  throw new Error('Unknown employment type');
}

export const decodeJobFormToJob = (job: AllJobFormData): BaseJobData => {
  const result: BaseJobData = {
    ...job,
    jobTypeId: job.jobTypeId,
    jobCertificateIds: job.jobCertificates.map((certificate) => certificate.id),
    jobRequirements: [
      ...(job.jobRequirements.skills || []),
      ...(job.jobRequirements.language || []),
      ...(job.jobRequirements.apparel || []),
    ],
  };

  if (job.isFreelance) {
    result.freelanceJob = {
      hourlyRateCents: Math.round(job.hourlyRate * 100),
      mutableUntilMinutes: Math.round(job.mutableUntilHours * 60),
    };
  }

  if (job.isTempwork) {
    result.tempWorkJob = {
      grossHourlyRateCents: Math.round(job.grossHourlyRate * 100),
      cla: job.cla,
    };
  }

  return result;
};

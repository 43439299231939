import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';

export const GrossHourlyRate = ({
  disabled,
  helperText,
  placeholder,
  required,
}: {
  disabled: boolean;
  helperText: string;
  placeholder: string;
  required: boolean;
}) => {
  const { control } = useFormContext<{ grossHourlyRate: number }>();
  return (
    <LwFormNumberInput
      name="grossHourlyRate"
      label={'Bruto uurloon'}
      control={control}
      rules={{ ...(required ? { required: 'Voer bruto uurloon in' } : {}) }}
      step={0.01}
      min={0}
      placeholder={placeholder}
      helperText={helperText}
      data-testid="gross-hourly-rate"
      disabled={disabled}
    />
  );
};

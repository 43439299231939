import { Auth } from 'aws-amplify';
import { AbstractApiService, TokenProvider } from './abstractApiService';
import { EnvironmentService } from './environment.service';

class SharedTokenProvider implements TokenProvider {
  public async currentToken(): Promise<string> {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }
}

export type FeatureFlags = {
  allowSelfRegistration: boolean;
  allowOpenSignUp: boolean;
  allowPayday: boolean;
  allowAlicia: boolean;
  allowProfileEditing: boolean;
  allowOnboardingStarterAccount: boolean;
  allowAutoEnrollingPayday: boolean;
  allowAutomaticIdentityVerification: boolean;
  allowExpenses: boolean;
  allowCertificateBlock: boolean;
  allowFreelancerEmploymentType: boolean;
  allowTempWorkerEmploymentType: boolean;
  allowRequiredVatForWorkActions: boolean;
  allowRequiredCocForWorkActions: boolean;
  allowPartners: boolean;
  allowPartnerRelations: boolean;
  allowCollectiveInvoices: boolean;
  allowAutomatedCollectiveInvoice: boolean;
  allowRequireGeneralAvailability: boolean;
  allowAvailability: boolean;
  allowProjectApplications: boolean;
  allowAutomaticShiftForwarding: boolean;
  allowPlatformServiceFee: boolean;
  allowCvScreensInOnboardingStarter: boolean;
  allowCandidateOnboarding: boolean;
  isSubmittedCvRequired: boolean;
  allowCsvForCollectiveInvoices: boolean;
  allowEmploymentId: boolean;
  allowReverseChargingVAT: boolean;
  disableTempWorkStarterOnboarding: boolean;
};

export type EnvironmentConfigurationResponse = {
  backend: {
    platformClient: string;
    featureFlags: FeatureFlags;
  };
  payment?: {
    platformClient: string;
    allowSalaryPaymentCreation: boolean;
  };
};

class SharedApiServiceImpl extends AbstractApiService {
  constructor(endpoint: string) {
    super(endpoint, new SharedTokenProvider());
  }

  public getEnvironmentConfiguration = async (): Promise<EnvironmentConfigurationResponse> => {
    const { backend, payment } = await this.getBackendEnvironmentConfiguration();

    return {
      payment,
      backend,
    };
  };

  private async getBackendEnvironmentConfiguration() {
    const response = await this.get<EnvironmentConfigurationResponse>({
      path: `environment-configuration`,
    });

    return response;
  }
}

const ogpUri = EnvironmentService.getVariable('BACKEND_OGP_URI').slice(0, -1).split('/');
ogpUri.pop();
export const endpoint = ogpUri.join('/') + '/';

export const SharedApiService = new SharedApiServiceImpl(endpoint);
